import React from 'react'

export default function CenterNews() {
  return (
    <div>

        
      
    </div>
  )
}
